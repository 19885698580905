<script>
import { ref } from 'vue';
import Swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import router from '../../router';
import axios from 'axios';

export default {
    setup() {
        const route = useRoute()
        const { redirectFrom } = route.query
        
        const loading = ref(false); 
        const checkSystemHealth = async () => {
            loading.value = true;
            await axios.get(process.env.VUE_APP_API_URL + '/health')
            .then((response) => {
                if (response.data.database) {
                    if (redirectFrom){ 
                        router.replace({ path: redirectFrom });
                    }else{
                        router.replace({ name: 'default' });
                    }
                }else{
                    stillOffline();
                }
            })
            .catch((error) => {
              console.log(error)
              stillOffline();
            });
            loading.value = false;
        };

        const stillOffline = () => {
            Swal.fire({
                icon: 'error',
                title: 'Ops!',
                text: 'Ainda estamos offline.',
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        };

        return {
            checkSystemHealth,
            loading
        };
    },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
   
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
          
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 pt-4">
              <div class="mb-5 text-white-50">
                <p class="display-5 coming-soon-text">Ops!</p>
                <h1 class="display-5 coming-soon-text">
                  Estamos Offline
                </h1>
                <div class="mt-4 pt-2">
                    <button v-if="loading" class="btn btn-success btn-load">
                        <span class="d-flex align-items-center">
                            <span class="spinner-border flex-shrink-0" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </span>
                            <span class="flex-grow-1 ms-2">
                                Checando status...
                            </span>
                        </span>
                    </button>
                    <button v-else class="btn btn-success" @click="checkSystemHealth()">
                        <i class="mdi mdi-refresh me-1"></i> Atualizar
                    </button>
                </div>
              </div>
              <div class="row justify-content-center mb-5">
                <div class="col-xl-4 col-lg-8">
                  <div>
                    <img
                      src="@/assets/images/offline.png"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                Copyright  &copy; {{ new Date().getFullYear() }} Rede São Roque - Apoio
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
